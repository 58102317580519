@charset "UTF-8";

/* div#contents
======================================= */

/* dl#recommend */
.recommendBox {
    margin-bottom: 30px;
    padding-top: 30px;
}
/* .recommendBox h3,
.templateBox h3 {
    font-weight: bold;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: solid 1px #ccc;
    color: #333;
} */
.recommendBox ul {
    overflow: hidden;
    _zoom:1;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
.recommendBox ul li {
    _float: left;
    _margin-right: 22px;
    margin-bottom: 20px;
}
.recommendBox ul .last {
    _margin-right: 0;
}
.templateBox {
    margin-bottom: 30px;
    padding-top: 20px;
}
.templateBox p {
    margin-bottom: 20px;
}
h2.img-move {
    margin: auto;
    width: 1000px;
}
.img-move a:hover {
    position: relative;
    top: -1px;
    left: -1px;
}
.img-move a:active {
    position: relative;
    top: 1px;
    left: 1px;
}
.templateBox ul li {
    display: inline;
    margin-right: 22px;
}
.templateBox ul .last {
    margin-right: 0;
}
.choiceBox {
    margin-bottom: 30px;
    padding-top: 30px;
}
.choiceBox .lastBox {
    border: none;
    margin-bottom:20px;
}
.choiceItems {
    margin-bottom: 30px;
    overflow: hidden;
    _zoom:1;
}

h2.font13 {
    font-size: 14px;
    font-weight: bold;
}
.choiceBox h3,
.newsBox h3 {
    padding-left: 25px;
    margin-bottom: 20px;
    background: url(../../public/images/ttl_bg3.gif) left top no-repeat;
    _height: 50px;
    line-height: 50px;
    font-size: 130%;
    color: #fff;
    background-size: 100% 50px;
    _height: 36px;
    _line-height: 36px;
    _font-size: 108%;
    padding-left: 50px;
}
.newsBox h3 a {color: #fff;text-decoration: none;}
.choiceBox h4 {
    padding-bottom: 5px;
    margin-bottom: 10px;
    border-bottom: solid 1px #ccc;
    color: #333;
    font-size: 109%;
}

.choiceList {
    width: 1000px;
    overflow: hidden;
    _zoom:1;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
.choiceList li {
    _float: left;
    width: 300px;
    margin:0 11px 50px 0;
}
.choiceList li span {
    display: block;
}
.choiceList .first {
    _clear: both;
}
.choiceList .last {
    margin-right: 0;
}
.choiceItem {
    width: 350px;
    _float: left;
    overflow: hidden;
    _zoom:1;
    display: flex;
    justify-content: space-between;
}
.choiceItem .photo img {
    width:150px ;
    _float: left;
    height: 118px;
}

.choiceList li {
    display: flex;
    justify-content: space-between;
}
.choiceItem .txt {
    _float: right;
    _width:200px ;
    _font-size: 92%;
    line-height: 1.5;
    _height: 134.2px;
}
.choiceItem .txt a {
    color: #333;
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
}
.letter-spacing b {
    letter-spacing: -1px;
}
.choiceItem .txt a:hover {
    text-decoration: none;
    opacity: 0.7;
}
.txt b {
    font-weight: bold;
    display:block;
    font-size: 16px;
}

.products-card {
    color: #333;
    background: rgb(169,27,75,0.2);
    border-left: solid 7px #A91B4B;
}

.mt-10 {
    margin-top: 0;
}

.products-card-name{
    margin: 0;
    padding: 0;
    text-align: center;
    font-weight: bold;
    _font-size: 8px;
    color: #333;
    font-size: 15px;
}

.products-card-price{
    _font-size: 14px;
    color: #f14c3f;
    margin-top: -5px;
    font-size: 20px;
}

.newsBox {
    margin-bottom:30px;
}
.newsBox h3 {
    margin-bottom: 20px;
}
.newsBox dl {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: solid 1px #ccc;
    overflow: hidden;
    _zoom:1;
}
.newsBox .last {
    border-bottom: none;
    padding-bottom: 0;
}
.newsBox dl dt {
    float: left;
    padding-left: 5px;
    width:100px;
    font-size: 109%;
    font-weight: bold;
    color:#000;
}
.newsBox dl dd {
    float: right;
    width: calc(100% - 105px);
}
.newsBox dl dd > p {
    margin-top:8px;
}
.newsBox dl dd strong {
    display: block;
    color:#A91B4B;
}

.boundary-line{
    border-bottom: 3px solid #ccc;
    margin-bottom: 20px;
}

/* div#mainVisual
======================================= */
div#mainVisual {
    position: relative;
    width: 653px;
    height: 328px;
    overflow: hidden;
    margin: 0px 0 30px;
}
div#mainVisual .navi{
    position: absolute;
    left: 408px;
    top: 279px;
    z-index: 50;
    width: 250px;
}

div#mainVisual .navi ul li {
    width: 40px;
    height: 40px;
    float: left;
    display: inline;
    margin-right: 8px !important;
}
div#mainVisual .navi ul li a {
    display: block;
    margin: 1px;
}
div#mainVisual .navi li a img{}
div#mainVisual .navi li a.active{
    margin: 0 !important;
}
div#mainVisual .navi a.active img{
    border: 1px solid #fff;
}
div#mainVisual .navi-image {
    position:relative;
    width: 650px;
    height: 328px;
}
div#mainVisual .navi-image a { display:block; }
div#mainVisual .navi-image a { position:absolute; top:0; left:0; z-index:8; }
div#mainVisual .navi-image a.active { z-index:10; }
div#mainVisual .navi-image a.last-active { z-index:9; }
div#mainVisual .navi-image {
    float:left;
}

.red {
    color: #f14c3f;
}
